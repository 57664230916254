import { PractitionerCard } from "@cruncho/cruncho-shared-types";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import { api } from "services/api";
import CopyPractitionersEmail from "./CopyPractitionersEmail";
import { PractitionersListItem } from "./PractitionersListItem";

export function PractitionersList() {
	const [showCopyPractitioners, setShowCopyPractitioners] = useState(false);
	const [searchQuery, setSearchQuery] = useState<string>("");
	const [practitioners, setPractitioners] = useState<PractitionerCard[]>([]);
	const [onlyPending, setOnlyPending] = useState<boolean>(false);

	// Filtered lists for each category
	const [filteredPractitioners, setFilteredPractitioners] = useState<
		PractitionerCard[]
	>([]);
	const [filteredStudios, setFilteredStudios] = useState<PractitionerCard[]>([]);
	const [filteredSpaces, setFilteredSpaces] = useState<PractitionerCard[]>([]);

	const fetchPractitioners = async (): Promise<PractitionerCard[]> => {
		const result = await api.practitioner.getAll();
		return result;
	};

	useEffect(() => {
		fetchPractitioners().then((fetchedPractitioners) =>
			setPractitioners(fetchedPractitioners),
		);
	}, []);

	useEffect(() => {
		// Filter logic based on search query and pending status
		const filterBySearch = (item: PractitionerCard) => {
			if (!searchQuery) return true;

			if (!item.isStudio && !item.isSpace) {
				return (
					item.firstname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
					item.lastname?.toLowerCase().includes(searchQuery.toLowerCase())
				);
			}
			return item.placename?.toLowerCase().includes(searchQuery.toLowerCase());
		};

		const filterByPendingStatus = (item: PractitionerCard) => {
			if (!onlyPending) return true;
			return item.verifiedStatus !== "approved";
		};

		// Filter practitioners (isPractitioner=true, isStudio=false, isSpace=false)
		setFilteredPractitioners(
			practitioners.filter(
				(item) =>
					!item.isStudio &&
					!item.isSpace &&
					filterBySearch(item) &&
					filterByPendingStatus(item),
			),
		);

		// Filter studios (isPractitioner=true, isStudio=true)
		setFilteredStudios(
			practitioners.filter(
				(item) =>
					item.isStudio === true &&
					filterBySearch(item) &&
					filterByPendingStatus(item),
			),
		);

		// Filter spaces (isPractitioner=true, isSpace=true)
		setFilteredSpaces(
			practitioners.filter(
				(item) =>
					item.isSpace === true &&
					filterBySearch(item) &&
					filterByPendingStatus(item),
			),
		);
	}, [practitioners, searchQuery, onlyPending]);

	const totalFilteredCount =
		filteredPractitioners.length + filteredStudios.length + filteredSpaces.length;

	return (
		<Card style={{ maxHeight: "70vh", overflow: "auto" }}>
			<CardHeader
				title="Practitioners, Studios & Spaces"
				action={<Typography>{totalFilteredCount}</Typography>}
				style={{
					position: "sticky",
					top: 0,
					backgroundColor: "white",
					zIndex: 1000,
				}}
			/>
			<CardContent>
				{practitioners.length > 0 && (
					<>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setShowCopyPractitioners(true);
							}}
							className="mb-4"
						>
							Get Practitioners&apos; Email
						</Button>

						<CopyPractitionersEmail
							users={practitioners ?? []}
							show={showCopyPractitioners}
							handleClose={() => setShowCopyPractitioners(false)}
						/>
						<TextField
							fullWidth
							label="Search"
							onChange={(event) => {
								setSearchQuery(event.target.value);
							}}
							value={searchQuery}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: searchQuery && (
									<InputAdornment position="end">
										<IconButton
											onClick={() => setSearchQuery("")}
											size="large"
											aria-label="clear"
										>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						<Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: 2 }}>
							<TextField
								type="checkbox"
								label="Pending"
								onChange={() => {
									setOnlyPending(!onlyPending);
								}}
							/>
							<span className="ml-3">Pending only</span>
						</Box>
					</>
				)}

				<Grid container spacing={2}>
					{/* Practitioners Column */}
					<Grid item xs={12} md={4}>
						<Card variant="outlined">
							<CardHeader
								title="Practitioners"
								action={<Typography>{filteredPractitioners.length}</Typography>}
							/>
							<CardContent>
								{filteredPractitioners.length > 0 ? (
									<List>
										{filteredPractitioners.map((practitioner) => (
											<PractitionersListItem
												key={practitioner.userId}
												practitioner={practitioner}
												displayName={`${practitioner.firstname || ""} ${practitioner.lastname || ""}`}
											/>
										))}
									</List>
								) : (
									<Typography variant="body2" color="textSecondary">
										No practitioners available
									</Typography>
								)}
							</CardContent>
						</Card>
					</Grid>

					{/* Studios Column */}
					<Grid item xs={12} md={4}>
						<Card variant="outlined">
							<CardHeader
								title="Studios"
								action={<Typography>{filteredStudios.length}</Typography>}
							/>
							<CardContent>
								{filteredStudios.length > 0 ? (
									<List>
										{filteredStudios.map((studio) => (
											<PractitionersListItem
												key={studio.userId}
												practitioner={studio}
												displayName={studio.placename || ""}
											/>
										))}
									</List>
								) : (
									<Typography variant="body2" color="textSecondary">
										No studios available
									</Typography>
								)}
							</CardContent>
						</Card>
					</Grid>

					{/* Spaces Column */}
					<Grid item xs={12} md={4}>
						<Card variant="outlined">
							<CardHeader
								title="Spaces"
								action={<Typography>{filteredSpaces.length}</Typography>}
							/>
							<CardContent>
								{filteredSpaces.length > 0 ? (
									<List>
										{filteredSpaces.map((space) => (
											<PractitionersListItem
												key={space.userId}
												practitioner={space}
												displayName={space.placename || ""}
											/>
										))}
									</List>
								) : (
									<Typography variant="body2" color="textSecondary">
										No spaces available
									</Typography>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}
