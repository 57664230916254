import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

export function Home() {
	return (
		<Box>
			<Card>
				<CardHeader title="Welcome to the admin website!" />
				<CardContent>
					Here are some links that can be useful when working at Cruncho. To find
					more information about what each of them is used for, feel free to go on
					the{" "}
					<a
						href="https://wiki.cruncho.co"
						rel="noreferrer noopener"
						target="_blank"
					>
						wiki
					</a>
					.
				</CardContent>
			</Card>
			<Card>
				<CardHeader title="General" />
				<CardContent>
					<div>
						<a
							href="https://wiki.cruncho.co"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Wiki</b>
						</a>
						: Our Wiki, gathering a lot of information about the code base
					</div>
					<div>
						<a
							href="https://trello.com/b/qs9wt7Cx/cruncho"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Trello</b>
						</a>
						: Our main project management tool in the Tech Team
					</div>
					<div>
						<a
							href="https://bitbucket.org/dashboard/overview"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>BitBucket</b>
						</a>
						: Our Git repository management platform; we only use the
						cruncho-turborepo, cruncho_stack and iframesdk repositories at the moment,
						the rest is legacy
					</div>
					<div>
						<a
							href="https://docs.google.com/spreadsheets/d/15RA6Luuo310u2yVL6NFmhD8uiyvSIKqrlDqzQ_8PVvY/edit#gid=1346205796"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Clients specifications sheet</b>
						</a>
						: The Google Sheets file where the Sales Team writes each client and its
						specifications for the guide creation
					</div>
				</CardContent>
			</Card>
			<Card>
				<CardHeader title="Monitoring" />
				<CardContent>
					<div>
						<a
							href="https://one.eu.newrelic.com/dashboards/detail/MzQ1MzM3OHxWSVp8REFTSEJPQVJEfGRhOjM0MjEx?account=3453378&state=0627ef0f-ca19-d103-974d-baf74e45de12"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>New Relic - Infrastructure</b>
						</a>
						: Our infrastructure overview dashboard; New Relic is a free monitoring
						agent and because of that, we have one account for the infrastructure
						overview (hello@cruncho.com) and one for the logs generated by our
						services (victor@cruncho.com)
					</div>
					<div>
						<a
							href="https://one.eu.newrelic.com/nr1-core?account=3353292&duration=1800000&filters=%28domain%20IN%20%28%27APM%27%2C%20%27EXT%27%29%20AND%20type%20IN%20%28%27APPLICATION%27%2C%20%27SERVICE%27%29%29&state=5c0303a1-d6bd-c344-d2b4-63c008452524"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>New Relic - Logs</b>
						</a>
						: Our logs gathering platform - <b>not up to date</b>; New Relic is a free
						monitoring agent and because of that, we have one account for the
						infrastructure overview (hello@cruncho.com) and one for the logs generated
						by our services (victor@cruncho.com)
					</div>
					<div>
						<a
							href="https://ingestion.cruncho.in/monitor/queues"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Ingestion</b>
						</a>
						: The dashboard where we can interact with the Bull queues used by the
						Scrapers service
					</div>
					<div>
						<a
							href="https://uptime.cruncho.co/dashboard"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Uptime Kuma</b>
						</a>
						: Our self-hosted monitoring service, it regularly checks that our web
						services are up - <b>not up to date</b>; takes a lot of time to load the
						dashboard, also exists for staging with .in
					</div>
					<div>
						<a
							href="http://cruncho.co:8080/dashboard/"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Traefik</b>
						</a>
						: Traefik automatically generated dashboard to see available subdomains
						and overall health status; also exists for staging with .in
					</div>
					<div>
						<a
							href="https://cruncho.eu.pagerduty.com/incidents"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Pagerduty</b>
						</a>
						: Our service supposed to call Victor when a critical alert is raised -{" "}
						<b>not up to date</b>
					</div>
					<div>
						<a
							href="https://analytics.google.com/analytics/web/#/report-home/a36127368w188706352p185206106"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Google Analytics</b>
						</a>
						: Our main analytics service
					</div>
					<div>
						<a
							href="https://analytics.cruncho.co/"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Matomo</b>
						</a>
						: Our self-hosted analytics service for clients who do not want to have
						anything going through Google; also exists for staging with .in
					</div>
				</CardContent>
			</Card>
			<Card>
				<CardHeader title="Infrastructure" />
				<CardContent>
					<div>
						<a
							href="https://dash.cloudflare.com/7eda800e99a3dc09f68b0bf2574f1617"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Cloudflare</b>
						</a>
						: Our domain name management platform
					</div>
					<div>
						<a
							href="https://cloud.digitalocean.com/projects/201e80b3-d8b0-409c-8013-c0932e41c02f/resources?i=c704ba"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Digital Ocean - Resources</b>
						</a>
						: Our cloud resources management platform; Digital Ocean is our cloud
						provider and it does not allow to have an account other than the owner
						that can both manage resources (hello@cruncho.com) and see the billing
						(simon2.cruncho@gmail.com)
					</div>
					<div>
						<a
							href="https://cloud.digitalocean.com/account/billing?i=c704ba"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Digital Ocean - Billing</b>
						</a>
						: Our cloud resources billing interface; Digital Ocean is our cloud
						provider and it does not allow to have an account other than the owner
						that can both manage resources (hello@cruncho.com) and see the billing
						(simon2.cruncho@gmail.com)
					</div>
					<div>
						<a
							href="https://eu-west-1.console.aws.amazon.com/console/home?region=eu-west-1#"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Amazon Web Services</b>
						</a>
						: Our provider for some web services, namely{" "}
						<a
							href="https://s3.console.aws.amazon.com/s3/buckets?region=eu-west-1"
							rel="noreferrer noopener"
							target="_blank"
						>
							S3 buckets
						</a>{" "}
						(storage) and{" "}
						<a
							href="https://s3.console.aws.amazon.com/s3/buckets?region=eu-west-1"
							rel="noreferrer noopener"
							target="_blank"
						>
							Cognito
						</a>{" "}
						(authentication)
					</div>
				</CardContent>
			</Card>
			<Card>
				<CardHeader title="Development tools" />
				<CardContent>
					<div>
						<a
							href="https://kibana.cruncho.co/app/dev_tools#/console"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Kibana</b>
						</a>
						: Self-hosted tool to interact with ElasticSearch and see the data we are
						working with; also exists for staging with .in
					</div>
					<div>
						<a
							href="https://api-ts.cruncho.co/"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Cruncho API</b>
						</a>
						: Our API; useful to reindex some recommendation
						(/update-index/stockholm?ids=123456) or decode some recommendation hash
						(/cms/decode/FFFFFF); also exists for staging with .in
					</div>
					<div>
						<a href="https://geojson.io" rel="noreferrer noopener" target="_blank">
							<b>Geo JSON</b>
						</a>
						: The tool we usually use to draw polygons for new destinations
					</div>
					<div>
						<a
							href="https://events-api.cruncho.co/swagger/"
							rel="noreferrer noopener"
							target="_blank"
						>
							<b>Events API Swagger</b>
						</a>
						: The documentation we offer clients who use the Events API; also exists
						for staging with .in
					</div>
				</CardContent>
			</Card>
		</Box>
	);
}
