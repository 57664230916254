import { z } from "zod";

namespace PaymentStatus {
	export const PENDING = "pending";
	export const SUCCESS = "success";
	export const FAILURE = "failure";
	export const CANCELED = "canceled";
}

export type PaymentStatusOption =
	| typeof PaymentStatus.PENDING
	| typeof PaymentStatus.SUCCESS
	| typeof PaymentStatus.FAILURE
	| typeof PaymentStatus.CANCELED;

export const PaymentStatusEnum = z.enum([
	PaymentStatus.PENDING,
	PaymentStatus.SUCCESS,
	PaymentStatus.FAILURE,
	PaymentStatus.CANCELED,
]);

// same as stripe refund status
namespace PaymentRefundStatus {
	export const PENDING = "pending";
	export const REQUIRES_ACTION = "requires_action";
	export const SUCCEEDED = "succeeded";
	export const FAILED = "failed";
	export const CANCELED = "canceled";
}

export type PaymentRefundStatusOption =
	| typeof PaymentRefundStatus.PENDING
	| typeof PaymentRefundStatus.REQUIRES_ACTION
	| typeof PaymentRefundStatus.SUCCEEDED
	| typeof PaymentRefundStatus.FAILED
	| typeof PaymentRefundStatus.CANCELED;

export const PaymentRefundStatusEnum = z.enum([
	PaymentRefundStatus.PENDING,
	PaymentRefundStatus.REQUIRES_ACTION,
	PaymentRefundStatus.SUCCEEDED,
	PaymentRefundStatus.FAILED,
	PaymentRefundStatus.CANCELED,
]);

/**
 * Onboarding user requirements
 */
export const requirementsSchema = z.object({
	currentlyDue: z.array(z.string()).default([]),
	pastDue: z.array(z.string()).default([]),
});

export const onboardingSchema = z.object({
	stripeId: z.string().optional(),
	completedStripeOnboarding: z.boolean().optional(),
	payoutsEnabled: z.boolean().optional(),
	requirements: requirementsSchema.optional(),
});

export type OnboardingRequirements = z.infer<typeof requirementsSchema>;

export type Onboarding = z.infer<typeof onboardingSchema>;
