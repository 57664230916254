import { SelectField, SwitchField } from "@cruncho/components";
import { TaxOption } from "@cruncho/cruncho-shared-types";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { FieldArray, useField } from "formik";
import { useEffect, useRef, useState } from "react";
import { api } from "services/api";
import Stripe from "stripe";

const defaultOption: TaxOption[][number] = {
	taxRateId: "",
	default: false,
};

interface OptionItemProps {
	/**
	 * The path of the formik value of the current option
	 */
	name: string;
	/**
	 * Tax options avaliable on Stripe
	 */
	taxOptions: Stripe.TaxRate[];
	/**
	 * Callback triggered when removing the option
	 */
	remove: () => void;
}

/**
 * Component displaying an editable option from taxOptions
 */
function OptionItem({ name, taxOptions, remove }: OptionItemProps) {
	return (
		<Grid container spacing={1} alignItems="center">
			<Grid item xs={6}>
				<SelectField
					size="small"
					fullWidth
					label="Tax"
					name={`${name}.taxRateId`}
					items={
						taxOptions.map(
							({ id, display_name, country, inclusive, percentage }) => ({
								value: id,
								label: `${display_name}(${inclusive ? "inclusive" : "exclusive"}): ${percentage}% (${country})`,
							}),
						) ?? []
					}
					variant="outlined"
				/>
			</Grid>
			<Grid item xs={4} justifyContent="center">
				<SwitchField label="Default" name={`${name}.default`} />
			</Grid>

			<Grid item xs={2}>
				<IconButton />
				<Button variant="outlined" color="error" onClick={remove}>
					<DeleteIcon />
				</Button>
			</Grid>
		</Grid>
	);
}

/**
 * Component displaying a list of of tax allowing creation deletion
 */
export function TaxOptionsCard() {
	// The list of options that are displayed in this component
	// we add an additional key property to be able to pass correct key property
	// A counter takes care of creating uniques keys through the keyCounter ref
	const [taxOptions, setTaxOptions] = useState<Stripe.TaxRate[]>([]);
	const options =
		useField<Array<TaxOption[][number] & { key: number }>>(
			"features.eventManagerFeatures.availableTaxes",
		)[0].value ?? [];

	useEffect(() => {
		const getTaxes = async () => {
			const ticketingTaxes = await api.stripe.getAllTaxes();
			setTaxOptions(ticketingTaxes);
		};

		getTaxes();
	}, []);

	const keyCounter = useRef(0);

	function getNextKey() {
		keyCounter.current += 1;
		return keyCounter.current;
	}

	return (
		<Card>
			<CardHeader
				subheader={
					<Typography variant="h6" gutterBottom>
						Tax Options
					</Typography>
				}
			/>

			<CardContent>
				<FieldArray
					name="features.eventManagerFeatures.availableTaxes"
					render={(arrayHelpers) => (
						<Grid container rowSpacing={2} justifyContent="center">
							{options.map((taxOption, index) => (
								<Grid item xs={12} key={taxOption.taxRateId}>
									<OptionItem
										name={`features.eventManagerFeatures.availableTaxes[${index}]`}
										taxOptions={taxOptions}
										remove={() => arrayHelpers.remove(index)}
									/>
									<Divider />
								</Grid>
							))}

							<Grid item>
								<Button
									variant="outlined"
									type="submit"
									onClick={() => {
										// passing an unique key to identify the new option
										arrayHelpers.push({ key: getNextKey(), ...defaultOption });
									}}
								>
									Add new tax
								</Button>
							</Grid>
						</Grid>
					)}
				/>
			</CardContent>
		</Card>
	);
}
