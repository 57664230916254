import { z } from "zod";
import { AuthSources } from "./authTypes";
import { Destination, LandingPageCategoryConfiguration } from "./destination";
import { ALL_APIS, l1Schema } from "./recommendation/properties";
import { Interest } from "./searchTypes";
import { contactInfoSchema } from "./soulcircus";
import { dateSchema } from "./utilities";

export interface SlugWeight {
	explore: number;
	enjoy: number;
}

export interface CalibrationWeight {
	tag: string;
	partner: SlugWeight;
	friends: SlugWeight;
	kids: SlugWeight;
	alone: SlugWeight;
}

export interface Calibration {
	slugWeights: CalibrationWeight[];
	_id: any;
}

export const categoryMappingSchema = z.object({
	_id: z.string(),
	api: z.enum(ALL_APIS),
	apiCategoryId: z.string(),
	apiCategoryName: z.string().optional(),
	/**
	 * Default unassigned value is "to_edit"
	 */
	crunchoSlug: z.string().default("to_edit"),
	destinationSlug: z.string().optional(), // overwrites other settings with the same api_category_id for the specified destination
});

export type CategoryMapping = z.infer<typeof categoryMappingSchema>;

export const categoryMappingSchemaCreateInput = categoryMappingSchema.omit({
	_id: true,
});

export type CategoryMappingCreateInput = z.infer<
	typeof categoryMappingSchemaCreateInput
>;

export interface GoogleCategoryMapping extends CategoryMapping {
	api: "google";
	apiName: string;
	apiCategoryId: string;
	cruncho_slug: string;
}

export function isGoogleCategoryMapping(
	categoryMapping: CategoryMapping,
): categoryMapping is GoogleCategoryMapping {
	return categoryMapping.api === "google";
}

export interface CityInstance extends Destination {
	_id: string;
}
export interface FavoritesEntity {
	_id: string; // This is a mongo ObjectId, not a string !
	destinationSlug: string;
	createdAt: Date;
	modifiedAt?: Date;
	anonymousId?: string;
	userId?: string;
	carousels: LandingPageCategoryConfiguration[];
	practitionerIds?: string[];
	sessionIds?: string[];
	studioIds?: string[];
	spaceIds?: string[];
	recommendationIds: number[];
	subscribed: boolean;
	language?: string;
}

export interface UserFavorites {
	uid: string;
	carousels: LandingPageCategoryConfiguration[];
	practitionerIds?: string[];
	recommendationIds: string[];
	sessionIds?: string[];
	studioIds?: string[];
	spaceIds?: string[];
	destinationSlug: string;
	subscribed: boolean;
	language?: string;
}

export interface GooglePhotoObject {
	_id: string;
	url: string;
}

export interface Sequence {
	sequence: string;
	value: number;
}

export interface OnboardingPills {
	interests: Interest[];
}

const subsSchema = z.object({
	google: z.string().optional(),
	facebook: z.string().optional(),
	cognito: z.string().optional(),
	apple: z.string().optional(),
	laprovence: z.string().optional(),
	email: z.string().optional(),
});

export const guidesUserSchema = z.object({
	_id: z.string(),
	adminGroup: z.string().optional(),
	email: z.string().optional(),
	ip: z.string().optional(),
	locale: z.string().optional(),
	logins: z.array(z.string()).optional(),
	name: z.string(),
	pictureUrl: z.string().optional(),
	subs: subsSchema,
});
/**
 * This is the mongo Document equivalent of OAuthUserData
 */
export interface GuidesUser {
	/**
	 * For now this is the equivalent of the OAuthUserData "sub". We should stop specifying it
	 * ourselves and use the subs field, because each auth provider has different subs
	 */
	_id: string; // This is a mongo ObjectId, not a string !
	adminGroup?: string;
	email?: string;
	ip?: string;
	locale?: string;
	logins: Array<string>;
	name: string;
	pictureUrl?: string;
	subs: Partial<Record<AuthSources, string>>;
}

export const wordMatchSchema = z.object({
	word: z.string().transform((s) => s.trim()),
	l1Slug: l1Schema,
	l3Slug: z.string(),
	destinationSlug: z.string().default("default"),
	weight: z
		.number()
		.gte(0)
		.lte(100)
		.describe(
			"From 0 to 100, how important is this weight compared to other matches",
		),
});

export type WordMatch = z.infer<typeof wordMatchSchema>;

export const soulcircusUserSchema = z.object({
	_id: z.string().optional(),
	ip: z.string().optional(),
	locale: z.string().optional(),
	logins: z.array(z.string()).optional(),
	practitionerIds: z.string().array().optional(),
	subs: subsSchema,
	type: z.string().optional(),
	isAdmin: z.boolean().optional(),
	// Main information :
	name: z.string(),
	pictureUrl: z.string().optional(),
	email: z.string().email().optional(),
	firstname: z.string().min(1).optional(),
	lastname: z.string().min(1).optional(),
	createdAccountAt: dateSchema.or(z.string()).optional(),
	location: z.string().optional(),
	additionalAddress: z.string().optional(),
	city: z.string().optional(),
	country: z.string().optional(),
	phone: z.string().optional(),
	// store info that used for payment
	paymentInfo: contactInfoSchema.optional(),
});

export type SoulcircusUser = z.infer<typeof soulcircusUserSchema>;
