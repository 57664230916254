// participants pay a service fee on top of this event price, based on the total purchase amount
// (15 SEK for purchases up to 300 SEK / 25 SEK for purchases between 301-1,000 SEK / 2.5% for purchases over 1,000 SEK).

export const calculateApplicationFee = (totalTicketPrice: number): number => {
	if (totalTicketPrice <= 300) {
		return 15;
	}

	if (totalTicketPrice > 300 && totalTicketPrice <= 1000) {
		return 25;
	}

	return totalTicketPrice * 0.025;
};
