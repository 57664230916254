import { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ProductView } from "views/product/ProductView";
import { PractitionersListView } from "views/soulcircus/PractitionerListView";
import ProtectedRoute from "./ProtectedRoute";
import { paths } from "./routes";
import { BugReportsView } from "./views/bugReports/BugReportsView";
import { CategoriesView } from "./views/categories/CategoriesView";
import { CategoryMappingView } from "./views/categoryMapping/CategoryMappingView";
import { DestinationsView } from "./views/destinations/DestinationsView";
import { FormatsView } from "./views/formats/FormatsView";
import { Home } from "./views/home/Home";
import { IframeGeneratorView } from "./views/iframeGenerator/IframeGeneratorView";
import LoginView from "./views/login/LoginView";
import { PractitionerView } from "./views/practitioner/PractitionerView";
import { SoulcircusView } from "./views/soulcircus/SoulcircusView";
import { WidgetGeneratorView } from "./views/widgetGenerator/WidgetGeneratorView";

/**
 * The main router of the admin website
 */

export const CustomRouter = () => (
	<div>
		<Suspense fallback={<div>Loading...</div>}>
			<Routes>
				<Route path={paths.login} element={<LoginView />}></Route>
				<Route
					path={paths.bugReports}
					element={
						<ProtectedRoute>
							<BugReportsView />
						</ProtectedRoute>
					}
				/>
				<Route
					path={paths.category}
					element={
						<ProtectedRoute>
							<CategoriesView />
						</ProtectedRoute>
					}
				/>
				<Route
					path={paths.categoryMapping}
					element={
						<ProtectedRoute>
							<CategoryMappingView />
						</ProtectedRoute>
					}
				/>
				<Route
					path={paths.format}
					element={
						<ProtectedRoute>
							<FormatsView />
						</ProtectedRoute>
					}
				/>
				<Route path={paths.destination}>
					<Route
						path={":id"}
						element={
							<ProtectedRoute>
								<DestinationsView />
							</ProtectedRoute>
						}
					/>
					<Route
						path={""}
						element={
							<ProtectedRoute>
								<DestinationsView />
							</ProtectedRoute>
						}
					/>
				</Route>
				<Route
					path={paths.iframeGenerator}
					element={
						<ProtectedRoute>
							<IframeGeneratorView />
						</ProtectedRoute>
					}
				/>
				<Route
					path={paths.widgetGenerator}
					element={
						<ProtectedRoute>
							<WidgetGeneratorView />
						</ProtectedRoute>
					}
				/>
				<Route
					path={paths.soulcircus}
					element={
						<ProtectedRoute>
							<SoulcircusView />
						</ProtectedRoute>
					}
				/>
				<Route
					path={paths.practitioner}
					element={
						<ProtectedRoute>
							<PractitionerView />
						</ProtectedRoute>
					}
				/>
				<Route
					path={paths.practitioners}
					element={
						<ProtectedRoute>
							<PractitionersListView />
						</ProtectedRoute>
					}
				/>
				<Route
					path={paths.product}
					element={
						<ProtectedRoute>
							<ProductView />
						</ProtectedRoute>
					}
				/>
				<Route
					path={paths.root}
					element={
						<ProtectedRoute>
							<Home />
						</ProtectedRoute>
					}
				/>
				{/* fallback to home */}
				<Route path="*" element={<Navigate to={paths.root} />} />
			</Routes>
		</Suspense>
	</div>
);
