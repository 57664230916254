import { z } from "zod";
import { latLngSchema } from "../destination";
import { dateSchema } from "../utilities";
import { onboardingSchema } from "../stripe";

export const practitionerReviewSchema = z.object({
	content: z.string().max(1200, "Review is too long"),
	date: z.string(),
	name: z.string(),
	rating: z.number(),
	reviewerId: z.string(),
	answer: z.string().max(1200, "Review is too long").optional(),
	answerDate: z.string().optional(),
	slotId: z.string(),
	practitionerId: z.string(),
	_id: z.string().optional(),
});

export type PractitionerReview = z.infer<typeof practitionerReviewSchema>;

export const practitionerFileSchema = z.object({
	credit: z.string().optional(),
	file: z.custom<File>().optional(),
	height: z.number().optional(),
	name: z.string(),
	title: z.string().optional(),
	url: z.string().optional(),
	width: z.number().optional(),
});

export type PractitionerFile = z.infer<typeof practitionerFileSchema>;

export const experienceSectionSchema = z.object({
	description: z
		.string()
		.refine((text) => text.replace(/<[^>]+>/g, "").length <= 400),
	endDate: z.string(),
	city: z.string().optional(),
	country: z.string().optional(),
	place: z.string().optional(),
	startDate: z.string(),
	currentlyInTheRole: z.boolean().optional().default(false),
	title: z.string(),
});

export type ExperienceSection = z.infer<typeof experienceSectionSchema>;

export const certificationsSectionSchema = z.object({
	description: z
		.string()
		.refine((text) => text.replace(/<[^>]+>/g, "").length <= 400),
	endDate: z.string(),
	city: z.string().optional(),
	country: z.string().optional(),
	place: z.string().optional(),
	startDate: z.string(),
	title: z.string(),
});

export type CertificationsSection = z.infer<typeof certificationsSectionSchema>;

export namespace TextFieldStatus {
	export const LIVE = "live";
	export const DRAFT = "draft";
	export const DELETED = "deleted";
}

export type TEXT_FIELD_STATUS =
	| typeof TextFieldStatus.LIVE
	| typeof TextFieldStatus.DRAFT
	| typeof TextFieldStatus.DELETED;

export const TEXT_FIELD_STATUS_ENUM = z.enum([
	TextFieldStatus.LIVE,
	TextFieldStatus.DRAFT,
	TextFieldStatus.DELETED,
]);

export const textFieldSchema = z.object({
	status: TEXT_FIELD_STATUS_ENUM.default("draft"),
	text: z.string().default(""),
});

export type TextField = z.infer<typeof textFieldSchema>;

// Add any fields that we want users to be able to create translations for in this section
export const languageSection = z.object({
	summary: textFieldSchema,
	about: textFieldSchema,
	profession: textFieldSchema,
});

export const translationSchema = z.object({
	en: languageSection,
	sv: languageSection,
});
export type Translation = z.infer<typeof translationSchema>;

export const FAQFieldSchema = z
	.object({
		status: TEXT_FIELD_STATUS_ENUM.default("draft"),
		question: z.string().max(100, "Too long").optional(),
		answer: z.string().max(400, "Too long").optional(),
	})
	.refine((faq) => {
		if (faq.status === "live") {
			if (faq.question && faq.question?.length > 100) return false;
			if (faq.answer && faq.answer?.length > 400) return false;
		}
		return true;
	});

export const FAQTranslationSchema = z.object({
	en: FAQFieldSchema,
	sv: FAQFieldSchema,
});

export type FAQ = z.infer<typeof FAQTranslationSchema>;

namespace VerifiedStatus {
	export const NOREQUEST = "no_request";
	export const PENDING = "pending";
	export const APPROVED = "approved";
	export const REJECTED = "rejected";
}

export type VERIFIED_STATUS =
	| typeof VerifiedStatus.NOREQUEST
	| typeof VerifiedStatus.PENDING
	| typeof VerifiedStatus.APPROVED
	| typeof VerifiedStatus.REJECTED;

export const VERIFIED_STATUS_ENUM = z.enum([
	VerifiedStatus.NOREQUEST,
	VerifiedStatus.PENDING,
	VerifiedStatus.APPROVED,
	VerifiedStatus.REJECTED,
]);

export const POSSIBLE_VERIFIED_STATUS: readonly VERIFIED_STATUS[] = [
	VerifiedStatus.NOREQUEST,
	VerifiedStatus.PENDING,
	VerifiedStatus.APPROVED,
	VerifiedStatus.REJECTED,
];

namespace SubscriptionPlan {
	export const ESSENTIAL = "essential";
	export const PRO = "pro";
	export const WORK = "work";
}

export const videoFileSchema = z.object({
	name: z.string(),
	url: z.string(),
	isUploadedFile: z.boolean().default(true),
});
export type VideoFile = z.infer<typeof videoFileSchema>;

export const SUBSCRIPTION_PLAN_ENUM = z.enum([
	SubscriptionPlan.ESSENTIAL,
	SubscriptionPlan.PRO,
	SubscriptionPlan.WORK,
]);

export const summarySchema = z.object({
	summary: z
		.string()
		.refine((text) => text.replace(/<[^>]+>/g, "").length <= 2600),
});

// Space information schema
export const spaceInformationSchema = z.object({
	maxCapacity: z.number(),
	yogaMats: z.number(),
	totalArea: z.number(),
	livingArea: z.number(),
	usableAreaInside: z.number(),
	usableAreaOutside: z.number(),
	rooms: z.number(),
	bathrooms: z.number(),
	toilets: z.number(),
	doubleBeds: z.number(),
	singleBeds: z.number(),
});

export type SpaceInformation = z.infer<typeof spaceInformationSchema>;

export const practitionerSchema = z
	.object({
		userId: z.string(),
		uniqueURL: z
			.string()
			.min(1)
			.regex(/^[a-zA-Z0-9-_]+$/),
		firstVisit: z.boolean().optional(),
		isSponsored: z.boolean().optional(),
		isFeatured: z.boolean().optional(),
		hasAtWork: z.boolean().optional(),
		averageRating: z.number().optional(),

		// First type of category : used to select them on carousels on the listing page
		listingCategories: z.string().array().optional(),

		// Second type of category : different types of sickness/status that the practitioner could heal -> shown on the PractitionerMiniCard
		issuesCategories: z.string().array().optional(),

		// Space Services
		spaceServices: z.string().array().optional(),
		basicAmenities: z.string().array().optional(),
		premiumAmenities: z.string().array().optional(),

		// Main information :
		placename: z.string().min(1).optional(),
		firstname: z.string().min(1).optional(),
		lastname: z.string().min(1).optional(),
		type: z.string().optional(),
		translations: translationSchema,

		// Video file :
		videoFile: videoFileSchema.optional(),

		createdAccountAt: dateSchema.or(z.string()).optional(),

		// Pictures :
		coverPicture: practitionerFileSchema.optional(),
		profilePicture: practitionerFileSchema.optional(),

		// Session section :
		location: z.string().optional(),
		additionalAddress: z.string().optional(),
		city: z.string().optional(),
		country: z.string().optional(),
		geometry: latLngSchema,
		languages: z.string().array().optional(),
		numberSessions: z.number().optional(),
		companyName: z.string().optional(),
		email: z.string().email().nullable().optional(),
		phone: z.string().optional(),
		companyNumber: z
			.string()
			.regex(/^\d{10}(\d{2})?$/)
			.nullable()
			.optional(),
		noCompany: z.boolean().default(false).optional(),
		verifiedStatus: VERIFIED_STATUS_ENUM,
		subscriptionStatus: z
			.object({
				subscriptionId: z.string(),
				startDate: dateSchema,
				bookingNumber: z.number(),
				active: z.boolean(),
				customerId: z.string(),
				canceled: z.boolean(),
			})
			.optional(),
		isProfileLive: z.boolean().optional(),
		isStudio: z.boolean().default(false),
		isSpace: z.boolean().default(false),

		// tags for spaces
		spaceTags: z.string().array().optional(),

		// If it's a studio, track member practitioner IDs
		studioMemberPractitionerIds: z.string().array().optional(),

		// Category section :
		categorySection: z
			.object({
				categories: z.string().array(),
			})
			.optional(),

		// Social links section :
		instagramLink: z.string().optional(),
		tiktokLink: z.string().optional(),
		linkedinLink: z.string().optional(),
		youtubeLink: z.string().optional(),
		xLink: z.string().optional(),
		facebookLink: z.string().optional(),
		websiteLink: z.string().optional(),

		// Space specific information
		spaceInformation: spaceInformationSchema.optional(),

		// Gallery section :
		gallerySection: practitionerFileSchema.array().optional(),

		// Experience section :
		experienceSection: experienceSectionSchema.array().optional(),

		// Certifications section :
		certificationsSection: certificationsSectionSchema.array().optional(),

		// Recommended Events section :
		recommendedEventsId: z.string().array().optional(),

		// FAQ section :
		FAQ: FAQTranslationSchema.array().default([]),

		profileViews: z.number().optional(),
	})
	// Stripe onboarding schema
	.merge(onboardingSchema);

export type Practitioner = z.infer<typeof practitionerSchema>;

export const practitionerCardSchema = z.object({
	_id: z.string().optional(),
	userId: z.string(),
	isSponsored: z.boolean().optional(),
	hasAtWork: z.boolean().optional(),
	averageRating: z.number().optional(),

	// Main information :
	placename: z.string().min(1).optional(),
	firstname: z.string().min(1).optional(),
	lastname: z.string().min(1).optional(),
	uniqueURL: z
		.string()
		.min(1)
		.regex(/^[a-zA-Z0-9-_]+$/),
	type: z.string().optional(),
	email: z.string().email().nullable().optional(),
	isStudio: z.boolean().optional().default(false),
	isSpace: z.boolean().optional().default(false),
	translations: translationSchema,
	// First type of category : used to select them on carousels on the listing page
	listingCategories: z.string().array().optional(),

	// Second type of category : different types of sickness/status that the practitioner could heal -> shown on the PractitionerMiniCard
	issuesCategories: z.string().array().optional(),

	// Space specific information
	spaceInformation: spaceInformationSchema.optional(),

	// tags for space profile
	spaceTags: z.string().array().optional(),

	// third type of category
	categorySection: z
		.object({
			categories: z.string().array(),
		})
		.optional(),

	// Profile picture :
	profilePicture: practitionerFileSchema.optional(),

	FAQ: FAQTranslationSchema.array().default([]),

	// Session section :
	location: z.string().optional(),
	city: z.string().optional(),
	country: z.string().optional(),
	geometry: latLngSchema,
	languages: z.string().array().optional(),
	verifiedStatus: VERIFIED_STATUS_ENUM,
	companyName: z.string().optional(),
	companyNumber: z
		.string()
		.regex(/^\d{10}$/)
		.nullable()
		.optional(),
});

/**
 * Type for giving only enough information for the practitioners mini card
 */
export type PractitionerCard = z.infer<typeof practitionerCardSchema>;

export const responsePractitionerByCategorySchema = z.object({
	category: z.string(),
	practitionerList: practitionerCardSchema.array(),
});

export type ResponsePractitionerByCategory = z.infer<
	typeof responsePractitionerByCategorySchema
>;
