import {
	AppLanguage,
	EventDate,
	TranslationFunction,
} from "@cruncho/cruncho-shared-types";
import { isSameDay } from "date-fns";

export const sortByDate = (arrayOfDate: string[]) =>
	[...arrayOfDate].sort((a, b) => {
		const bDate = new Date(b);
		const aDate = new Date(a);
		return aDate.getTime() - bDate.getTime();
	});

export const extractClosestDate = (
	datesArray: string[],
	dateFilter: string,
) => {
	const currentDateFilter = new Date(dateFilter).getTime();
	return datesArray.find((date) => {
		const currentDate = new Date(date).getTime();
		return currentDate >= currentDateFilter;
	});
};

export const extractNextDate = (
	eventEnd: string[],
	eventStart: string[],
	dateFilter: string,
) => {
	// Closest end date of the event from the start date picked
	const endDateDisplay = extractClosestDate(eventEnd, dateFilter);
	// Closest start date of the event from the start date picked
	const startDateDisplay = extractClosestDate(eventStart, dateFilter);

	if (endDateDisplay && startDateDisplay) {
		const eventIndex = eventEnd.indexOf(endDateDisplay);
		// If an event is ongoing, we display its previous start date
		// If not, we display its next starting date
		return endDateDisplay >= startDateDisplay
			? startDateDisplay
			: eventStart[eventIndex];
	}
	if (endDateDisplay && !startDateDisplay) {
		const eventIndex = eventEnd.indexOf(endDateDisplay);
		// If there is no startDate greater than the filter
		// But there is an endDate, we return the corresponding startDate
		// TBH, I don't really understand the logic behind the comment above
		return eventStart[eventIndex];
	}
	return startDateDisplay;
};

export function getEventDateDisplay(
	eventStart: string | string[] | undefined,
	locale: AppLanguage | undefined,
	t: TranslationFunction,
	fullDate = false,
	startDateFilter?: string,
	eventEnd?: string | string[] | undefined,
): string | null {
	let nextDate: string | undefined;
	if (typeof eventStart === "string") {
		nextDate = eventStart;
	} else if (eventStart) {
		const sortedEventStart = sortByDate(eventStart);
		if (startDateFilter && startDateFilter !== "" && eventEnd) {
			if (Array.isArray(eventEnd)) {
				const sortedEventEnd = sortByDate(eventEnd);
				nextDate = extractNextDate(
					sortedEventEnd,
					sortedEventStart,
					startDateFilter,
				);
			}
		} else if (eventEnd) {
			// In case there is no date filter specified, this case happens for preview carousels in L1 page
			if (Array.isArray(eventEnd)) {
				const sortedEventEnd = sortByDate(eventEnd);
				nextDate = extractNextDate(
					sortedEventEnd,
					sortedEventStart,
					new Date().toString(),
				);
			}
		} else {
			// By default, date displayed is picked according to the user's time and the starting date of the event
			const newDate = new Date().getTime();
			const nextOccurence = eventStart.find((start) => {
				const currentDate = new Date(start).getTime();
				return currentDate >= newDate;
			});
			if (nextOccurence) {
				nextDate = eventStart[eventStart.indexOf(nextOccurence) - 1];
			} else {
				nextDate = eventStart[-1];
			}
		}
	}

	let lastEndDate: string | undefined;
	if (typeof eventEnd === "string") {
		lastEndDate = eventEnd;
	} else if (eventEnd) {
		lastEndDate = eventEnd.at(-1);
	}

	if (nextDate) {
		const itHappensToday = isSameDay(new Date(), new Date(nextDate));
		const dateInSeconds = new Date(nextDate).getTime();
		const endDateInSeconds = lastEndDate
			? new Date(lastEndDate).getTime()
			: undefined;
		const now = new Date().getTime();
		const currentDateTime = startDateFilter
			? Math.max(now, new Date(startDateFilter).getTime())
			: now;
		const isOngoing = currentDateTime >= dateInSeconds;
		const firstDayNextYear = new Date(new Date().getFullYear() + 1, 0, 1);
		const isNextYear = dateInSeconds >= firstDayNextYear.getTime();
		const isPast = endDateInSeconds && endDateInSeconds < currentDateTime;
		const firstDayLastYear = new Date(new Date().getFullYear() - 1, 0, 1);
		const isLastYear = dateInSeconds < firstDayLastYear.getTime();

		let eventDateDisplay = !isOngoing
			? [
					...(!itHappensToday
						? [
								new Date(nextDate).toLocaleDateString(locale, {
									day: "2-digit",
									month: fullDate ? "long" : "short",
									weekday: fullDate ? "long" : undefined,
									year: isNextYear ? "numeric" : undefined,
								}),
							]
						: [t("Today")]),
					new Date(nextDate).toLocaleTimeString(locale, {
						hour: "2-digit",
						minute: "2-digit",
					}),
				].join(" - ")
			: isPast
				? [
						[
							new Date(nextDate).toLocaleDateString(locale, {
								day: "2-digit",
								month: fullDate ? "long" : "short",
								weekday: fullDate ? "long" : undefined,
								year: isLastYear ? "numeric" : undefined,
							}),
						],
						new Date(nextDate).toLocaleTimeString(locale, {
							hour: "2-digit",
							minute: "2-digit",
						}),
					].join(" - ")
				: t("happening now", "Happening Now");

		if (Array.isArray(eventStart)) {
			const nbRemainingDates =
				eventStart.length - eventStart.indexOf(nextDate) - 1;
			if (nbRemainingDates > 0) {
				eventDateDisplay +=
					nbRemainingDates === 1
						? ` (+1 ${t("date")})`
						: ` (+${nbRemainingDates} ${t("dates")})`;
			}
		}

		return eventDateDisplay;
	}
	return null;
}

export function isOngoingOccurrence(date: EventDate) {
	const today = new Date();
	return date.startDate < today && today < date.endDate;
}

/**
 * Get next occurrences of an event (including if one is ongoing).
 * @param dates - **Sorted** events dates
 * @returns Remaining event dates (including ongoing one).
 */
export function getNextOccurrences(dates: EventDate[]): EventDate[] {
	const todaysDate = new Date();

	const newEventDates: EventDate[] = [];

	dates.forEach((eventDate) => {
		if (eventDate.startDate > todaysDate || isOngoingOccurrence(eventDate)) {
			newEventDates.push(eventDate);
		}
	});

	return newEventDates;
}

export function formatEventDate(
	{ startDate, endDate }: EventDate,
	isOngoing: boolean,
	nbExtraEvents: number,
	{
		t,
		dateOptions,
		timeOptions,
		selectedLanguage,
		hideEventStartTime,
		hideEventEndTime,
		useDefaultStartTime,
		useDefaultEndTime,
	}: {
		t: TranslationFunction;
		dateOptions: Intl.DateTimeFormatOptions;
		timeOptions: Intl.DateTimeFormatOptions;
		selectedLanguage: AppLanguage;
		hideEventStartTime?: boolean;
		hideEventEndTime?: boolean;
		useDefaultStartTime?: boolean;
		useDefaultEndTime?: boolean;
	},
) {
	let finalString = "";

	// Hide start time if useDefaultStartTime, hide end time if useDefaultEndTime

	// For example: söndag 26 januari
	const startDateLocale = startDate.toLocaleString(
		selectedLanguage,
		dateOptions,
	);
	// 17:00
	const startTimeLocale = useDefaultStartTime
		? ""
		: startDate.toLocaleString(selectedLanguage, timeOptions);

	const endDateLocale = endDate.toLocaleString(selectedLanguage, dateOptions);
	const endTimeLocale = useDefaultEndTime
		? ""
		: endDate.toLocaleString(selectedLanguage, timeOptions);

	if (isOngoing) {
		if (
			endDate &&
			endDate.getTime() !== startDate.getTime() &&
			hideEventEndTime !== true
		) {
			// Will render: Happening now until ${endDate}
			finalString += `${t("happening now until", "Happening now until")} `; // Be aware of the space at the end
		} else {
			// Will render: Happening now (because in this case, hidenEventEndTime is true)
			finalString += t("happening now", "Happening Now");
		}
	} else if (hideEventStartTime !== true) {
		// Will render the start date time
		// If the end time within the same day will be ${startDate},${startTime} - ${endTime}
		// If not in the same day will be ${startDate},${startTime} - ${endDate},${endTime}
		finalString += `${startDateLocale}${useDefaultStartTime ? "" : ","} ${startTimeLocale}`;
	} else if (hideEventEndTime !== true) {
		// If hide the event start time only render the Ends on: ${endDate},${endTime}
		finalString += `${t("ends on", "Ends on")}: ${endDateLocale}${useDefaultEndTime ? "" : ","} ${endTimeLocale}`;
	} else {
		// If both event start time and event end time are hided
		// Render All day
		finalString += t("all day", "Happening All Day");
	}

	if (endDate && hideEventEndTime !== true && hideEventStartTime !== true) {
		startDate?.setSeconds(0, 0);
		endDate.setSeconds(0, 0);
		if (endDate.getTime() !== startDate.getTime()) {
			if (!isOngoing && !useDefaultEndTime) {
				finalString += " - ";
			}

			if (!isSameDay(startDate, endDate)) {
				finalString += endDateLocale;

				if (!useDefaultEndTime) {
					finalString += ", ";
				}
			}

			finalString += endTimeLocale;
		}
	}

	if (nbExtraEvents) {
		finalString +=
			nbExtraEvents === 1
				? ` (+1 ${t("Extra event count", "more")})`
				: ` (+${nbExtraEvents} ${t("Extra events count", "more")})`;
	}

	return finalString;
}
