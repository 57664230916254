import { APIBase } from "services/APIBase";
import Stripe from "stripe";

export class APIStripe extends APIBase {
	/**
	 * Get all taxes for event ticketing
	 * @returns
	 */
	async getAllTaxes() {
		const { data } = await this.api.get<{ data: Stripe.TaxRate[] }>(
			"/stripe/taxes",
		);
		return data.data;
	}
}
