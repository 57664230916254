import { SoulcircusCategory } from "@cruncho/cruncho-shared-types";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useEffect, useState } from "react";

import { SoulcircusCategoryListItem } from "./SoulcircusCategoryListItem";

type SoulcircusCategoryListProps = {
	/**
	 * The list of categories to display
	 */
	categories: SoulcircusCategory[];
	/**
	 * Name of the List
	 */
	name: string;
	/**
	 * Triggered when clicking on the button to edit a category
	 */
	onCategoryEditSelected: (category?: SoulcircusCategory) => void;
	/**
	 * Triggered when clicking on the button to delete a category
	 */
	onCategoryDeleteSelected: (
		category?: SoulcircusCategory,
		type?: string,
	) => void;
};

// List of category types that should have "categories" in the title
const CATEGORY_TYPES_WITH_CATEGORIES = [
	"listing",
	"issues",
	"profession",
	"space-services",
];

// Mapping for display titles
const DISPLAY_TITLES: Record<string, string> = {
	"space-services": "Space regions",
	"basic-amenities": "Space basic amenities",
	"premium-amenities": "Space premium amenities",
	"space-tags": "Space tags",
};

/**
 * A component displaying a list of categories that can be filtered by name
 *
 */
export function SoulcircusCategoryList({
	categories,
	name,
	onCategoryEditSelected,
	onCategoryDeleteSelected,
}: SoulcircusCategoryListProps) {
	/**
	 * A string used to filter the displayed categories
	 */
	const [searchQuery, setSearchQuery] = useState<string>("");

	/**
	 * The subset of categories kept while filtering
	 */
	const [filteredCategories, setFilteredCategories] = useState<
		SoulcircusCategory[]
	>([]);

	/**
	 *  When the parent changes the list of categories, sort them alphabetically and apply search quey
	 *  Will search by slug and name
	 */
	useEffect(() => {
		if (searchQuery) {
			setFilteredCategories(
				categories.filter((category) => category.slug.includes(searchQuery)),
			);
		} else {
			setFilteredCategories(categories);
		}
	}, [categories, searchQuery]);

	// Format the title based on the name
	const formatTitle = (_name: string): string => {
		// Check if we have a special display title for this name
		if (DISPLAY_TITLES[_name]) {
			return DISPLAY_TITLES[_name];
		}

		// Standard cases: capitalize first letter and add "categories" if needed
		const shouldAddCategories = CATEGORY_TYPES_WITH_CATEGORIES.includes(_name);
		const capitalizedName = _name.charAt(0).toUpperCase() + _name.slice(1);
		return shouldAddCategories
			? `${capitalizedName} categories`
			: capitalizedName;
	};

	return (
		<Card>
			<CardHeader
				title={formatTitle(name)}
				action={<Typography>({filteredCategories.length})</Typography>}
				style={{
					position: "sticky",
					top: 0,
					backgroundColor: "white",
					zIndex: 1000,
				}}
			/>
			{categories.length > 0 && (
				<div style={{ padding: "8px 16px" }}>
					<TextField
						fullWidth
						label="Search"
						onChange={(event) => {
							setSearchQuery(event.target.value);
						}}
						value={searchQuery}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: searchQuery && (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearchQuery("")}
										size="large"
										aria-label="clear"
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</div>
			)}
			<div
				style={{
					maxHeight: "33vh",
					overflowY: "auto",
					scrollbarWidth: "thin",
					scrollbarColor: "rgba(144, 143, 143, 0.5) transparent",
					padding: "8px 16px",
				}}
			>
				{filteredCategories.length > 0 ? (
					<List>
						{filteredCategories.map((category) => (
							<SoulcircusCategoryListItem
								key={category.slug}
								category={category}
								onEditSelected={(_category) => onCategoryEditSelected(_category)}
								onDeleteSelected={(_category) =>
									onCategoryDeleteSelected(_category, name)
								}
							/>
						))}
					</List>
				) : (
					<Box margin={2}>
						<Typography variant="body2" color="textSecondary">
							No categories available
						</Typography>
					</Box>
				)}
			</div>
		</Card>
	);
}
