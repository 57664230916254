import { SessionMe } from "@cruncho/cruncho-shared-types";
import { AxiosInstance } from "axios";
import { paths } from "routes";
import {
	environmentToWebroot,
	environmentToUrl,
	currentEnvironment,
} from "./api";
import { APIBase } from "./APIBase";

/**
 * A class handling the interactions with auth related endpoints
 */
export class APIAuth extends APIBase {
	private token: string | null;

	private interceptor: number | null;

	private isDevelopment: boolean;

	constructor(instance: AxiosInstance) {
		super(instance);

		// Check if we're in a development environment
		this.isDevelopment =
			currentEnvironment === "localhost" || currentEnvironment === "local";

		// For local development, always set a fake token
		if (this.isDevelopment) {
			this.token = "fake-dev-token-for-bypass";
		} else {
			this.token = localStorage.getItem("token");
		}

		this.interceptor = null;
		if (this.token) {
			this.setInterceptor();
		}
	}

	private setInterceptor() {
		this.interceptor = this.api.interceptors.request.use((config) => {
			config.headers.Authorization = `Bearer ${this.token}`;

			return config;
		});
	}

	public async isConnected(): Promise<boolean> {
		// For local development, always return true to bypass auth check
		if (this.isDevelopment) {
			return true;
		}

		try {
			const session = await this.api.get<SessionMe>("/session/me");
			return !!session.data.adminGroup && session.data.adminGroup.superAdmin;
		} catch (error) {
			// For local development, don't logout on error
			if (this.isDevelopment) {
				console.warn("Auth error intercepted in dev mode");
				return true;
			}

			this.logout();
			throw error;
		}
	}

	public async connect() {
		// For local development, skip the actual connection flow
		if (this.isDevelopment) {
			this.token = "fake-dev-token-for-bypass";
			localStorage.setItem("token", this.token);
			if (!this.interceptor) {
				this.setInterceptor();
			}
			// Redirect to the admin panel directly instead of going through auth flow
			window.location.href = environmentToWebroot() + paths.root;
			return;
		}

		if (!this.token) {
			this.token = (await this.api.get("/session/new/admin")).data.token;
			localStorage.setItem("token", this.token ?? "");
		}
		if (!this.interceptor) {
			this.setInterceptor();
		}
		const redirect_uri = environmentToWebroot() + paths.login;
		window.location.assign(
			`${environmentToUrl[currentEnvironment]}/login/google?redirect_uri=${redirect_uri}&token=${this.token}`,
		);
	}

	public setToken(token: string) {
		this.token = token;
		localStorage.setItem("token", token);
	}

	public logout() {
		// For local development, maintain the fake token
		if (this.isDevelopment) {
			console.log("Logout intercepted in dev mode");
			return;
		}

		this.token = null;
		localStorage.removeItem("token");
		if (this.interceptor) {
			this.api.interceptors.request.eject(this.interceptor);
			this.interceptor = null;
		}
	}
}
