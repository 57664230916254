import {
	Area,
	Point,
	polygonSchema,
	Position,
} from "@cruncho/cruncho-shared-types";
import { barycenter, positionToLatLng } from "@cruncho/utils/helpers";

import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Send from "@mui/icons-material/Send";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LatLngLiteral, polygon } from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { FeatureGroup, MapContainer, TileLayer } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

type AreaEditorProps = {
	/**
	 * A bool to disable the text fields
	 */
	isGlobal?: boolean;
	/**
	 * The area to edit
	 */
	area: Area;
	/**
	 * A default center used to display the map. You can use the destination geo center
	 */
	defaultCenter: Point;
	/**
	 * A callback triggered when validating changes
	 */
	onChange: (area: Area) => void;
};

/**
 * Get the barycenter of an array of coordinates and convert it in {lng, lat} format
 * @param coordinates
 */
const barycenterLngLat = (coordinates: Position[]) => {
	const bary = barycenter(coordinates);

	if (!bary) {
		return undefined;
	}

	return {
		lng: bary[0],
		lat: bary[1],
	};
};

const StyledMapContainer = styled(MapContainer)`
	& a {
		color: #2c3f5c !important;
	}
`;

interface LeafletMapComponentProps {
	area: Area;
	defaultCenter: Point;
	leafletPolygons: LatLngLiteral[][];
	onEdit: (editEvent: any) => void; // react-leaflet-draw does not export this type
}
function LeafletMapComponent({
	area,
	defaultCenter,
	leafletPolygons,
	onEdit,
}: LeafletMapComponentProps) {
	const center =
		!!area.lat && !!area.lng
			? { lat: area.lat, lng: area.lng }
			: barycenterLngLat(area.polygon.coordinates[0]) ??
				positionToLatLng(defaultCenter.coordinates);

	const groupRef = useRef<any>(null);

	useEffect(() => {
		window.dispatchEvent(new Event("resize")); // Trick to make Leaflet map not appear grey
	}, []);

	useEffect(() => {
		if (groupRef.current) {
			groupRef.current.clearLayers();
			groupRef.current.addLayer(
				polygon(leafletPolygons, {
					color: "red",
				}),
			);
		}
	}, [leafletPolygons]);

	return (
		<StyledMapContainer
			center={[center.lng, center.lat]}
			zoom={13}
			className="w-full h-full"
		>
			<TileLayer
				url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a> contributors | <a href="https://www.hotosm.org/" target="_blank">Humanitarian OSM Team</a> | <a href="https://wiki.osmfoundation.org/wiki/Main_Page" target="_blank">OSM Foundation</a>'
			/>
			<FeatureGroup ref={groupRef}>
				<EditControl
					position="topright"
					draw={{
						rectangle: false,
						circle: false,
						marker: false,
						circlemarker: false,
						polyline: false,
						polygon: false,
					}}
					onEdited={onEdit}
				/>
			</FeatureGroup>
		</StyledMapContainer>
	);
}

/**
 * A component to edit ONE area
 */
export function AreaEditor({
	isGlobal,
	area,
	defaultCenter,
	onChange,
}: AreaEditorProps) {
	const { enqueueSnackbar } = useSnackbar();

	/**
	 * Edited fields
	 */
	const [name, setName] = useState(area.name);
	const [city, setCity] = useState(area.city ?? "");
	const [label, setLabel] = useState(area.label);
	const [onlyForCuration, setOnlyForCuration] = useState(area.onlyForCuration);
	// The coordinates displayed as a json string
	const [jsonCoord, setJsonCoord] = useState<string>("");
	const [leafletPolygons, setLeafletPolygons] = useState<LatLngLiteral[][]>([]);

	// Update the currently displayed area in case of parent changes
	useEffect(() => {
		setName(area.name);
		setCity(area.city ?? "");
		setLabel(area.label);
		setOnlyForCuration(area.onlyForCuration);
		setJsonCoord(
			JSON.stringify(
				area.polygon.coordinates.map((poly) =>
					poly.map((coord) => [coord.lng, coord.lat]),
				),
				null,
				4,
			),
		);
		setLeafletPolygons(
			area.polygon.coordinates.map((poly) => poly.map(positionToLatLng)),
		);
	}, [area]);

	// TODO: find proper type for layers
	/**
	 * A callback triggered when click save after editing the polygon(s)
	 */
	const updatePolygon = (editEvent: any) => {
		const layers = editEvent.layers?._layers;

		// Ensure layers is defined and not empty
		if (layers && Object.keys(layers).length > 0) {
			const layerKey = Object.keys(layers)[0];
			const layer = layers[layerKey];

			// Safely extract the new polygon coordinates
			const newPolygon = layer?._latlngs;

			if (newPolygon) {
				setLeafletPolygons(newPolygon);
			} else {
				console.error("Failed to update polygon: Invalid polygon data.");
			}
		} else {
			console.error("Failed to update polygon: No layers found.");
		}
	};

	/**
	 * A callback triggered when validating the area changes
	 */
	const handleSave = () => {
		// converting the polygon to our format
		const newPolygon: Area["polygon"] = {
			type: "Polygon",
			coordinates: leafletPolygons,
		};

		// Close the polygon if it is not
		if (
			newPolygon.coordinates[0][0].lng !==
				newPolygon.coordinates[0][newPolygon.coordinates.length - 1].lng ||
			newPolygon.coordinates[0][0].lat !==
				newPolygon.coordinates[0][newPolygon.coordinates.length - 1].lat
		) {
			newPolygon.coordinates[0].push(newPolygon.coordinates[0][0]);
		}

		onChange({
			...area,
			name,
			city,
			label,
			polygon: newPolygon,
			onlyForCuration,
		});
	};

	return (
		<Grid container justifyContent="center">
			<Grid item xs={12}>
				<Grid container spacing={2} alignItems="center">
					<Grid item>
						<TextField
							label="Area Name"
							disabled={isGlobal}
							value={name}
							onChange={(event) => setName(event.target.value)}
							variant="standard"
						/>
					</Grid>

					<Grid item>
						<TextField
							label="Label"
							disabled={isGlobal}
							value={label}
							onChange={(event) => setLabel(event.target.value)}
							variant="standard"
						/>
					</Grid>

					{!isGlobal && (
						<Grid item>
							<TextField
								label="Area city"
								value={city}
								onChange={(event) => setCity(event.target.value)}
								variant="standard"
							/>
						</Grid>
					)}

					<Grid item>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										value={onlyForCuration}
										onChange={(event) => setOnlyForCuration(event.target.checked)}
									/>
								}
								label="Only for curation"
							/>
						</FormGroup>
					</Grid>

					<Grid item>
						<Button
							variant="outlined"
							color="primary"
							startIcon={<Send />}
							onClick={handleSave}
							disabled={name.length === 0}
						>
							{name.length === 0
								? "Give this area a name"
								: `Confirm changes for ${name}`}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Typography paragraph variant="body2" color="textSecondary">
					Don&apos;t forget to click on &quot;Confirm&quot; before saving to save the
					changes on the current area.
				</Typography>
			</Grid>

			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item style={{ flex: 1 }}>
						<LeafletMapComponent
							area={area}
							defaultCenter={defaultCenter}
							leafletPolygons={leafletPolygons}
							onEdit={updatePolygon}
						/>
					</Grid>

					<Grid item xs={2}>
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							style={{ height: "100%" }}
						>
							<Grid item xs={12}>
								<Button
									fullWidth
									endIcon={<ChevronRight />}
									variant="outlined"
									onClick={() =>
										setJsonCoord(
											JSON.stringify(
												leafletPolygons.map((poly) =>
													poly.map((point) => [point.lng, point.lat]),
												),
												null,
												4,
											),
										)
									}
								>
									UPDATE JSON
								</Button>
							</Grid>
							<Grid item xs={12}>
								<Button
									fullWidth
									variant="outlined"
									startIcon={<ChevronLeft />}
									onClick={() => {
										try {
											// Extracting the JSON coordinates pasted in the text input
											const rawCoordinates: [number, number][][] = JSON.parse(jsonCoord);

											const convertedCoordinates = rawCoordinates.map((poly) =>
												poly.map((coord) => ({
													lat: coord[1],
													lng: coord[0],
												})),
											);

											// Trying to parse them with zod to avoid using ill-formated data
											const parsedCoordinates =
												polygonSchema.shape.coordinates.parse(convertedCoordinates);

											// Updating the current geo polygon with the new coordinates
											setLeafletPolygons(
												parsedCoordinates.map((poly) => poly.map(positionToLatLng)),
											);
										} catch (error) {
											console.error(error);
											enqueueSnackbar(String(error), {
												variant: "error",
												persist: true,
											});
										}
									}}
								>
									UPDATE MAP
								</Button>
							</Grid>
						</Grid>
					</Grid>

					<Grid item style={{ flex: 1 }}>
						<TextField
							fullWidth
							label="JSON"
							multiline
							value={jsonCoord}
							onChange={(event) => setJsonCoord(event.target.value)}
							minRows={24}
							maxRows={24}
						/>
						<i>
							If you want to use a MultiPolygon, just tell the tech team to change the
							&quot;type&quot; of the polygon in the DB to match it.
						</i>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
