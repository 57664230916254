import { SwitchInput } from "@cruncho/components";
import { Destination } from "@cruncho/cruncho-shared-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
	FormikDestinationWrapper,
	useSchemaConfig,
} from "../FormikDestinationWrapper";
import { BasicFeaturesSection } from "./BasicFeaturesSection";
import { ContactInformationSection } from "./ContactInformationSection";
import { DefaultLocationSection } from "./DefaultLocationSection";
import { EventCreationSection } from "./EventCreationSection";
import { EventDeletionSection } from "./EventDeletionSection";
import { PhoneFormatAccordion } from "./PhoneFormatAccordion";
import { PostOptionsCard } from "./PostOptionsCard";
import { TaxOptionsCard } from "./TaxOptionsCard";

type EventManagerTabProps = {
	/**
	 * The destination to edit
	 */
	destination: Destination;
	/**
	 * Triggered when clicking on save button
	 */
	onSave: (updatedDestination: Destination) => void;
};

/**
 * A tab displaying allowing edition of eventManager related info of a destination
 */
function InnerEventManagerTab() {
	const { enableEventManager, setEnableEventManager } = useSchemaConfig();

	return (
		<Accordion expanded={enableEventManager} sx={{ width: "100%" }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="h5">EventManager Features:</Typography>
				<SwitchInput
					label="Enable"
					value={enableEventManager}
					onChange={(event: any) => setEnableEventManager(event.target.checked)}
				/>
			</AccordionSummary>

			<AccordionDetails>
				<Stack spacing={4}>
					<BasicFeaturesSection />

					<DefaultLocationSection />

					<ContactInformationSection />

					<EventCreationSection />
					<EventDeletionSection />

					<PostOptionsCard />
					<TaxOptionsCard />
					<PhoneFormatAccordion />
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
}

/**
 * A tab displaying allowing edition of eventManager related info of a destination
 *
 * This component is a wrapper with a formik context around the @link {InnerEventManagerTab} component
 */
export function EventManagerTab({ onSave, destination }: EventManagerTabProps) {
	return (
		<FormikDestinationWrapper onSave={onSave} destination={destination}>
			<InnerEventManagerTab />
		</FormikDestinationWrapper>
	);
}
