import { Destination, SoulcircusCategory } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { api } from "services/api";
import { CreateSoulcircusCategoryDialog } from "./CreateSoulcircusCategoryDialog";
import { DeleteSoulcircusCategoryDialog } from "./DeleteSoulcircusCategoryDialog";
import { EditSoulcircusCategoryDialog } from "./EditSoulcircusCategoryDialog";
import { SoulcircusCategoryList } from "./SoulcircusCategoryList";

export function SoulcircusView() {
	const [destination, setDestination] = useState<Destination>();

	// Handling of creation/editing modals :
	const [openModal, setOpenModal] = useState<boolean>(false);

	// Handling of deletion modal :
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

	/**
	 * An object containing information about the current type selected category
	 */
	const [selectedType, setSelectedType] = useState<string>();

	/**
	 * An object containing information about the current selected category, if it is undefined, it means we're creating a new category, and not editing
	 */
	const [editingCategory, setEditingCategory] = useState<SoulcircusCategory>();

	/**
	 * Triggered when a category is selected for edition
	 */
	const handleEditCategory = (category?: SoulcircusCategory) => {
		setEditingCategory(category);
		setOpenModal(true);
	};

	/**
	 * Triggered when a category is selected for deletion
	 */
	const handleDeleteCategory = (
		category?: SoulcircusCategory,
		type?: string,
	) => {
		setSelectedType(type);
		setEditingCategory(category);
		setOpenDeleteModal(true);
	};

	/**
	 * Triggered when creating a format
	 */
	const handleAddCategory = () => {
		setEditingCategory(undefined);
		setOpenModal(true);
	};

	// Fetch function used to fetch different type of categories :
	const fetchCategoriesByType = async (
		type: string,
	): Promise<SoulcircusCategory[]> => {
		const fetchedData = await api.soulcircusCategory.getAllByType(type);

		return fetchedData;
	};

	const handleMaintenanceToggle = async () => {
		if (destination) {
			try {
				destination.isUnderMaintenance = !destination.isUnderMaintenance;
				const response = await api.destination.update(destination._id, destination);
				if (response) {
					setDestination(response);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	// Listing category : Determining in which carousels the practitioners are
	const [listingCategories, setListingCategories] = useState<
		SoulcircusCategory[]
	>([]);
	useEffect(() => {
		fetchCategoriesByType("listing").then((value) => setListingCategories(value));
	}, [openModal, openDeleteModal]);

	// Issues category : Categories shown on the mini practitioner card :
	const [issuesCategories, setIssuesCategories] = useState<SoulcircusCategory[]>(
		[],
	);
	useEffect(() => {
		fetchCategoriesByType("issues").then((value) => setIssuesCategories(value));
	}, [openModal, openDeleteModal]);

	// Profession category : Categories shown in the IntroSection of the practitioner's mini website
	const [professionCategories, setProfessionCategories] = useState<
		SoulcircusCategory[]
	>([]);
	useEffect(() => {
		fetchCategoriesByType("profession").then((value) =>
			setProfessionCategories(value),
		);
	}, [openModal, openDeleteModal]);

	// Space services category
	const [spaceServices, setSpaceServices] = useState<SoulcircusCategory[]>([]);
	useEffect(() => {
		fetchCategoriesByType("space-services").then((value) =>
			setSpaceServices(value),
		);
	}, [openModal, openDeleteModal]);

	// Basic amenities category
	const [basicAmenities, setBasicAmenities] = useState<SoulcircusCategory[]>([]);
	useEffect(() => {
		fetchCategoriesByType("basic-amenities").then((value) =>
			setBasicAmenities(value),
		);
	}, [openModal, openDeleteModal]);

	// Premium amenities category
	const [premiumAmenities, setPremiumAmenities] = useState<SoulcircusCategory[]>(
		[],
	);
	useEffect(() => {
		fetchCategoriesByType("premium-amenities").then((value) =>
			setPremiumAmenities(value),
		);
	}, [openModal, openDeleteModal]);

	const [spaceTags, setSpaceTags] = useState<SoulcircusCategory[]>([]);

	useEffect(() => {
		fetchCategoriesByType("space-tags").then((value) => setSpaceTags(value));
	}, [openModal, openDeleteModal]);

	// Getting all available destination and separating the bundled from the one without bundles
	useEffect(() => {
		const getDestinations = async () => {
			try {
				const response = await api.destination.GetById("soulcircus");
				if (response) {
					setDestination(response);
				}
			} catch (error) {
				console.error(error);
			}
		};

		getDestinations();
	}, []);

	return (
		<div className="p-4">
			<div className="flex gap-2">
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						handleAddCategory();
					}}
					className="mb-4"
				>
					Create new Category
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						handleMaintenanceToggle();
					}}
					className="mb-4"
				>
					{destination?.isUnderMaintenance
						? "Disable Maintenance Page"
						: "Enable Maintenance Page"}
				</Button>
			</div>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<SoulcircusCategoryList
						categories={listingCategories}
						name="listing"
						onCategoryEditSelected={handleEditCategory}
						onCategoryDeleteSelected={handleDeleteCategory}
					/>
				</Grid>
				<Grid item xs={3}>
					<SoulcircusCategoryList
						categories={issuesCategories}
						name="issues"
						onCategoryEditSelected={handleEditCategory}
						onCategoryDeleteSelected={handleDeleteCategory}
					/>
				</Grid>
				<Grid item xs={3}>
					<SoulcircusCategoryList
						categories={professionCategories}
						name="profession"
						onCategoryEditSelected={handleEditCategory}
						onCategoryDeleteSelected={handleDeleteCategory}
					/>
				</Grid>
				<Grid item xs={3}>
					<SoulcircusCategoryList
						categories={spaceServices}
						name="space-services"
						onCategoryEditSelected={handleEditCategory}
						onCategoryDeleteSelected={handleDeleteCategory}
					/>
				</Grid>
				<Grid item xs={3}>
					<SoulcircusCategoryList
						categories={basicAmenities}
						name="basic-amenities"
						onCategoryEditSelected={handleEditCategory}
						onCategoryDeleteSelected={handleDeleteCategory}
					/>
				</Grid>
				<Grid item xs={3}>
					<SoulcircusCategoryList
						categories={premiumAmenities}
						name="premium-amenities"
						onCategoryEditSelected={handleEditCategory}
						onCategoryDeleteSelected={handleDeleteCategory}
					/>
				</Grid>
				<Grid item xs={3}>
					<SoulcircusCategoryList
						categories={spaceTags}
						name="space-tags"
						onCategoryEditSelected={handleEditCategory}
						onCategoryDeleteSelected={handleDeleteCategory}
					/>
				</Grid>
			</Grid>
			{/* if the current category is undefined, it means we are in creation mode else, it's edit mode */}
			{editingCategory ? (
				<>
					<EditSoulcircusCategoryDialog
						open={openModal}
						category={editingCategory}
						onClose={() => setOpenModal(false)}
						onSave={async () => {
							setOpenModal(false);
							setEditingCategory(undefined);
						}}
					/>
					<DeleteSoulcircusCategoryDialog
						open={openDeleteModal}
						category={editingCategory}
						onClose={() => setOpenDeleteModal(false)}
						onDelete={() => {
							setSelectedType(undefined);
							setOpenDeleteModal(false);
							setEditingCategory(undefined);
						}}
						selectedType={selectedType ?? ""}
					/>
				</>
			) : (
				<CreateSoulcircusCategoryDialog
					open={openModal}
					onClose={() => setOpenModal(false)}
					onSave={async () => {
						setOpenModal(false);
						setEditingCategory(undefined);
					}}
				/>
			)}
		</div>
	);
}
