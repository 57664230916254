import { SelectField, SwitchField, TextField } from "@cruncho/components";
import {
	productPermissionsSchema,
	ProductTypeEnum,
	ReccuringEnum,
	SoulcircusProduct,
	soulcircusProductSugarcoatedSchema,
} from "@cruncho/cruncho-shared-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import axios, { AxiosError } from "axios";
import { Form, Formik, prepareDataForValidation } from "formik";
import { useSnackbar } from "notistack";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { api } from "../../services/api";

type EditProductDialogProps = {
	product: SoulcircusProduct;
	onClose: () => void;
	onSave: (product: SoulcircusProduct) => void;
	open: boolean;
};

/**
 * A dialog to edit an existing format
 */
export function EditProductDialog({
	product,
	onClose,
	onSave,
	open,
}: EditProductDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	const schema = soulcircusProductSugarcoatedSchema;

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<Formik
				enableReinitialize
				validationSchema={toFormikValidationSchema(schema)}
				initialValues={product}
				onSubmit={async (values) => {
					const preparedData = prepareDataForValidation(values);
					try {
						const parsedProduct = schema.parse(preparedData);

						try {
							if (!product._id) throw new Error("Must have id when editing");
							const updatedProduct = await api.product.update(
								product._id,
								parsedProduct,
							);

							enqueueSnackbar(`${updatedProduct.name} updated!`, {
								variant: "success",
							});

							onSave(updatedProduct);
						} catch (error: any) {
							console.error(error);
							if (
								axios.isAxiosError(error) &&
								(error as AxiosError<{ message: string }>).response?.data?.message
							) {
								enqueueSnackbar(
									(error as AxiosError<{ message: string }>).response?.data?.message,
									{
										variant: "error",
										persist: true,
									},
								);
							} else {
								enqueueSnackbar(JSON.stringify(error, null, 2), {
									variant: "error",
									persist: true,
								});
							}
						}
					} catch (error) {
						console.error(error);
						enqueueSnackbar(JSON.stringify(error, null, 2), {
							variant: "error",
							persist: true,
						});
					}
				}}
			>
				{({ values, dirty, isSubmitting }) => (
					<Form>
						<DialogTitle>Edit Product : {product._id}</DialogTitle>

						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										General information
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField fullWidth name="name" label="Name" variant="standard" />
										</Grid>
										<Grid item xs={6}>
											<TextField
												fullWidth
												name="description"
												label="Description"
												variant="standard"
											/>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										Money Related Informations
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												name="price"
												type="number"
												fullWidth
												label="Price"
												variant="standard"
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												name="promotionPrice"
												type="number"
												fullWidth
												label="Promotion Price ?"
												variant="standard"
											/>
										</Grid>
										{values.type === "subscription" && (
											<Grid item xs={12}>
												<Typography>
													An additional tax of 25% is automatically applied to the
													subscription on top of the price.
												</Typography>
											</Grid>
										)}
										<Grid item xs={6}>
											<SelectField
												name="type"
												size="small"
												fullWidth
												label="Product Type"
												items={ProductTypeEnum.options.map((option) => ({
													value: option,
													label: option,
												}))}
												variant="outlined"
											/>
										</Grid>
										<Grid item xs={6}>
											<SelectField
												name="recurring"
												size="small"
												fullWidth
												label="Reccurring ?"
												items={ReccuringEnum.options.map((option) => ({
													value: option,
													label: option,
												}))}
												variant="outlined"
											/>
										</Grid>
										{values.type === "subscription" && (
											<>
												<Grid item xs={6}>
													<TextField
														fullWidth
														type="number"
														name="bookingLimit"
														label="Booking Limit"
														variant="standard"
													/>
												</Grid>
												<Grid item xs={6}>
													<TextField
														fullWidth
														type="number"
														name="subscriptionLevel"
														label="Subscription Level"
														variant="standard"
													/>
												</Grid>
											</>
										)}
										<Grid item xs={12}>
											<SwitchField label="Active" name="active" color="secondary" />
										</Grid>
										{values.type === "subscription" && (
											<Grid item xs={12}>
												<Typography gutterBottom variant="h6">
													Permissions
												</Typography>
												<Grid container xs={12}>
													{Object.keys(productPermissionsSchema.shape).map((permission) => (
														<Grid key={permission} item xs={12}>
															<SwitchField
																label={permission}
																name={`permissions.${permission}`}
																color="secondary"
															/>
														</Grid>
													))}
												</Grid>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</DialogContent>

						<DialogActions>
							<Button onClick={onClose} color="primary">
								Cancel
							</Button>
							{dirty && (
								<Button
									type="submit"
									color="secondary"
									variant="outlined"
									disabled={isSubmitting}
								>
									Save
								</Button>
							)}
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
}
