import BugReportIcon from "@mui/icons-material/BugReport";
import CategoryIcon from "@mui/icons-material/Category";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import MapIcon from "@mui/icons-material/Map";
import PersonIcon from "@mui/icons-material/Person";
import WidgetIcon from "@mui/icons-material/Widgets";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";

import { useState } from "react";
import { Link as Routerlink, useNavigate } from "react-router-dom";

import { paths } from "./routes";
import { api, currentEnvironment } from "./services/api";

/**
 * The main header nav bar with dropdown menus
 */
function MainNav() {
	const navigate = useNavigate();

	// Menu anchor states
	const [categoriesAnchor, setCategoriesAnchor] = useState<HTMLElement | null>(
		null,
	);
	const [generatorsAnchor, setGeneratorsAnchor] = useState<HTMLElement | null>(
		null,
	);
	const [soulcircusAnchor, setSoulcircusAnchor] = useState<HTMLElement | null>(
		null,
	);

	// Open/close handlers for menus
	const handleCategoriesOpen = (event: React.MouseEvent<HTMLElement>) =>
		setCategoriesAnchor(event.currentTarget);
	const handleCategoriesClose = () => setCategoriesAnchor(null);

	const handleGeneratorsOpen = (event: React.MouseEvent<HTMLElement>) =>
		setGeneratorsAnchor(event.currentTarget);
	const handleGeneratorsClose = () => setGeneratorsAnchor(null);

	const handleSoulcircusOpen = (event: React.MouseEvent<HTMLElement>) =>
		setSoulcircusAnchor(event.currentTarget);
	const handleSoulcircusClose = () => setSoulcircusAnchor(null);

	// Logout handler
	const logout = () => {
		api.auth.logout();
		navigate(paths.login);
	};

	// Navigation helper
	const navigateTo = (path: string) => {
		navigate(path);
		// Close all open menus
		handleCategoriesClose();
		handleGeneratorsClose();
		handleSoulcircusClose();
	};

	return (
		<div>
			<AppBar position="fixed">
				<Toolbar>
					<Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
						{/* Logo/Home */}
						<Button
							component={Routerlink}
							to={paths.root}
							sx={{
								color: "inherit",
								display: "flex",
								alignItems: "center",
								mr: 1,
								ml: 1,
							}}
						>
							<DashboardIcon sx={{ mr: 1.5 }} />
							Admin
						</Button>

						{/* Destinations */}
						<Button
							color="inherit"
							component={Routerlink}
							to={paths.destination}
							sx={{ mr: 1 }}
							startIcon={<MapIcon />}
						>
							Destinations
						</Button>

						{/* Categories Dropdown */}
						<Button
							color="inherit"
							onClick={handleCategoriesOpen}
							endIcon={<ExpandMoreIcon />}
							startIcon={<CategoryIcon />}
							sx={{ mr: 1 }}
						>
							Categories
						</Button>
						<Menu
							anchorEl={categoriesAnchor}
							open={Boolean(categoriesAnchor)}
							onClose={handleCategoriesClose}
						>
							<MenuItem onClick={() => navigateTo(paths.category)}>
								Categories
							</MenuItem>
							<MenuItem onClick={() => navigateTo(paths.categoryMapping)}>
								Mappings
							</MenuItem>
						</Menu>

						{/* Generators Dropdown */}
						<Button
							color="inherit"
							onClick={handleGeneratorsOpen}
							endIcon={<ExpandMoreIcon />}
							startIcon={<WidgetIcon />}
							sx={{ mr: 1 }}
						>
							Generators
						</Button>
						<Menu
							anchorEl={generatorsAnchor}
							open={Boolean(generatorsAnchor)}
							onClose={handleGeneratorsClose}
						>
							<MenuItem onClick={() => navigateTo(paths.iframeGenerator)}>
								Iframe Generator
							</MenuItem>
							<MenuItem onClick={() => navigateTo(paths.widgetGenerator)}>
								Widget Generator
							</MenuItem>
						</Menu>

						{/* SoulCircus Dropdown */}
						<Button
							color="inherit"
							onClick={handleSoulcircusOpen}
							endIcon={<ExpandMoreIcon />}
							startIcon={<PersonIcon />}
							sx={{ mr: 1 }}
						>
							SoulCircus
						</Button>
						<Menu
							anchorEl={soulcircusAnchor}
							open={Boolean(soulcircusAnchor)}
							onClose={handleSoulcircusClose}
						>
							<MenuItem onClick={() => navigateTo(paths.soulcircus)}>
								Categories
							</MenuItem>
							<MenuItem onClick={() => navigateTo(paths.practitioners)}>
								Members
							</MenuItem>
							<MenuItem onClick={() => navigateTo(paths.format)}>Formats</MenuItem>
							<MenuItem onClick={() => navigateTo(paths.product)}>Products</MenuItem>
						</Menu>

						{/* Bug Reports */}
						<Button
							color="inherit"
							component={Routerlink}
							to={paths.bugReports}
							startIcon={<BugReportIcon />}
						>
							Bug Reports
						</Button>

						{/* Spacer to push environment and logout to the right */}
						<Box sx={{ flexGrow: 1 }} />

						{/* Environment Chip */}
						<Chip
							label={`ENV: ${currentEnvironment}`}
							color={currentEnvironment === "production" ? "error" : "info"}
							size="small"
							sx={{ mr: 2 }}
						/>

						{/* Logout Button */}
						<Tooltip title="Logout">
							<IconButton color="inherit" aria-label="logout" onClick={logout}>
								<LogoutIcon />
							</IconButton>
						</Tooltip>
					</Box>
				</Toolbar>
			</AppBar>

			{/* This empty Toolbar creates space below the AppBar */}
			<Toolbar />
		</div>
	);
}

export default MainNav;
