import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { PractitionersList } from "./PractitionersList";

/**
 * View component for displaying all practitioners
 */
export function PractitionersListView() {
	return (
		<div className="p-4 mt-16">
			<Paper elevation={2} className="p-4">
				<Box mb={3}>
					<Typography variant="h4" component="h1">
						Practitioners
					</Typography>
					<Typography variant="subtitle1" color="textSecondary">
						Manage all practitioners in the system
					</Typography>
				</Box>

				<PractitionersList />
			</Paper>
		</div>
	);
}
