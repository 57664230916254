import { z } from "zod";
import { currencyCodeSchema } from "../currencies";
import { PaymentRefundStatusEnum, PaymentStatusEnum } from "../stripe";
import { dateSchema } from "../utilities";

// I know I could import it from event/liveEvent, but then it makes zodToJsonSchema
// parsing fail for some reason
const eventDateSchema = z.object({
	startDate: z.date(),
	endDate: z.date(),
});

export enum RSVPAnswerStatusEnum {
	ALREADY_ANSWERED = "ALREADY_ANSWERED",
	MISSING_FIELD = "MISSING_FIELD",
	CANCELLED = "CANCELLED",
	BOOKED = "BOOKED",
	UNPAID = "UNPAID",
	CLEARED = "VALUE DELETED",
}

export enum RSVPAnswerAgeGroups {
	CHILD = "CHILD",
	ADULT = "ADULT",
	CLEARED = "VALUE DELETED",
}

export const ticketingPaymentRefundSchema = z.object({
	id: z.string(),
	status: PaymentRefundStatusEnum,
	// refund reason fill by user
	reason: z.string().optional(),
	// if refund failed, record the reason from stripe
	failureReason: z.string().optional(),
	createdAt: dateSchema,
	updatedAt: dateSchema.optional(),
});

export const RSVPPaymentMetadataSchema = z.object({
	answerId: z.string(),
	eventId: z.string(),
	customerEmail: z.string(),
});

export type RSVPPaymentMetadata = z.infer<typeof RSVPPaymentMetadataSchema>;

export const RSVPPaymentDetailSchema = z.object({
	// VAT percentage
	vat: z.number().optional(),
	// snapshot for the order
	ticketFee: z.number(),
	ticketFeeTax: z.number(),
	applicationFee: z.number(),
	// 25% for now
	applicationFeeVat: z.number().optional(),
	applicationFeeTax: z.number(),
	totalCharge: z.number(),
});

export type RSVPPaymentDetail = z.infer<typeof RSVPPaymentDetailSchema>;

export const RSVPPaymentSchema = z
	.object({
		receiptUrl: z.string().optional(),
		// stripe ids: checkout session id and payment intent id
		paymentSessionId: z.string().optional(),
		paymentIntentId: z.string().optional(),
		currency: currencyCodeSchema.default("SEK").optional(),
		minCancelAllowTime: z.number().min(0).optional().default(24), // hour

		status: PaymentStatusEnum,
		refund: ticketingPaymentRefundSchema.optional(),
		// the start and end time can be used to calculate when the log is out of date and when user is not able to cancel the order
		eventStartAt: dateSchema,
		eventEndAt: dateSchema,
		createdAt: dateSchema,
		updatedAt: dateSchema.optional(),
		canceledAt: dateSchema.optional(),
		cancelReason: z.string().optional(),
	})
	.merge(RSVPPaymentDetailSchema);

export type RSVPPayment = z.infer<typeof RSVPPaymentSchema>;

export const RSVPUserSchema = z.object({
	fullName: z.string(),
	ageGroup: z.nativeEnum(RSVPAnswerAgeGroups),
	age: z.number().optional(),
	specialDiet: z.string().optional(),
	priceId: z.string().optional(),
	// record the price detail in here for convenient, so we will be able to directly use it without querying
	price: z.number().min(0).optional(),
});
export type RSVPUser = z.infer<typeof RSVPUserSchema>;

export const RSVPAnswerSchema = z.object({
	_id: z.string(),
	answeredAt: z.date(),
	cancelToken: z.string(),
	company: z.string().optional(),
	dateSelected: eventDateSchema.optional(),
	eventId: z.string(),
	email: z.string().email(),
	phone: z.string().optional(),
	users: z.array(RSVPUserSchema),
	status: z.nativeEnum(RSVPAnswerStatusEnum),
	outdated: z.boolean().default(false),
	// Payment will only be effective if the event is not free
	paymentInfo: RSVPPaymentSchema.optional(),
});
export type RSVPAnswer = z.infer<typeof RSVPAnswerSchema>;
