import { SelectField, TextField } from "@cruncho/components";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MuiTextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import axios, { AxiosError } from "axios";
import { Form, Formik, prepareDataForValidation } from "formik";
import { useSnackbar } from "notistack";
import { sanitize } from "utils";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { api } from "../../services/api";

type CreateSoulcircusCategoryDialogProps = {
	onClose: () => void;
	onSave: (slug: string) => void;
	open: boolean;
};

/**
 * A dialog to create a new format
 */
export function CreateSoulcircusCategoryDialog({
	onClose,
	onSave,
	open,
}: CreateSoulcircusCategoryDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	const initialValues = {
		displayName: "",
		type: "",
	};

	const customSchema = z.object({
		displayName: z.string().min(1),
		type: z.string(),
	});

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={toFormikValidationSchema(customSchema)}
				onSubmit={async (values) => {
					const preparedData = prepareDataForValidation(values);

					try {
						const parsedCategory = customSchema.parse(preparedData);

						try {
							const categoryData = {
								slug: sanitize(parsedCategory.displayName),
								type: [parsedCategory.type],
							};
							const createdCategory =
								await api.soulcircusCategory.create(categoryData);

							enqueueSnackbar(`${values.displayName} created!`, {
								variant: "success",
							});

							// @ts-ignore
							onSave(createdCategory);
						} catch (error: any) {
							console.error(error);
							if (axios.isAxiosError(error) && error.response?.status === 400) {
								enqueueSnackbar("A category with the same slug/name already exists", {
									variant: "warning",
									persist: true,
								});
							} else if (
								axios.isAxiosError(error) &&
								(error as AxiosError<{ message: string }>).response?.data?.message
							) {
								enqueueSnackbar(
									(error as AxiosError<{ message: string }>).response?.data?.message,
									{
										variant: "error",
										persist: true,
									},
								);
							} else {
								enqueueSnackbar(JSON.stringify(error, null, 2), {
									variant: "error",
									persist: true,
								});
							}
						}
					} catch (error) {
						console.error(error);
						enqueueSnackbar(JSON.stringify(error, null, 2), {
							variant: "error",
							persist: true,
						});
					}
				}}
			>
				{({ values, dirty, isSubmitting }) => (
					<Form>
						<DialogTitle>Add a new category</DialogTitle>
						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										General information
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												fullWidth
												name="displayName"
												label="Name of the category"
												variant="standard"
											/>
										</Grid>

										<Grid item xs={6}>
											<MuiTextField
												fullWidth
												variant="standard"
												disabled
												label="The slug will be saved as "
												value={sanitize(values.displayName)}
											/>
										</Grid>

										<Grid item xs={6}>
											<SelectField
												size="small"
												fullWidth
												label="Type of category"
												name="type"
												items={
													[
														"listing",
														"issues",
														"profession",
														"space-services",
														"basic-amenities",
														"premium-amenities",
														"space-tags",
													].map((option) => ({
														value: option,
														label: option,
													})) ?? []
												}
												variant="outlined"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} color="primary">
								Cancel
							</Button>
							{dirty && (
								<Button
									type="submit"
									color="secondary"
									variant="outlined"
									disabled={isSubmitting}
								>
									Create
								</Button>
							)}
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
}
